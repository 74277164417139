import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { MangoMainLoader } from "./components/common/loaders";
import { registerLocale } from "react-datepicker";
import { momentLocaleConfig } from "./lang/index";
import es from "date-fns/locale/es";
import { cloneDeep } from "lodash";

// Se usa el locale de momentLocaleConfig ya que los meses están con las iniciales en mayúsculas
// En cambio los de es están en minúsculas
const parseLocale = (locale: Locale) => {
  let localeCopy = {
    ...cloneDeep(locale),
    localize: {
      ...locale.localize,
      month: (n: any) => momentLocaleConfig.es.months[n],
      day: (n: any) => momentLocaleConfig.es.weekdaysShort[n],
    },
  };
  return localeCopy;
};

registerLocale("es", parseLocale(es) as Locale);

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./App"));

const container = document.getElementById("root");

const root = createRoot(container as HTMLElement);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<MangoMainLoader />}>
        <App />
      </Suspense>
    </PersistGate>
  </Provider>
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
