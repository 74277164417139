import { ActionTypes } from "./actionTypes";
import { InitialStateType, Action } from "./types";
import initialState from "./initialState";
const {
  MENU_SET_CLASSNAMES,
  MENU_CONTAINER_ADD_CLASSNAME,
  MENU_CLICK_MOBILE_MENU,
  MENU_CHANGE_DEFAULT_CLASSES,
  MENU_CHANGE_HAS_SUB_ITEM_STATUS,
} = ActionTypes;

const reducer = (
  state: InitialStateType = initialState,
  action: Action
): InitialStateType => {
  switch (action.type) {
    case MENU_CHANGE_HAS_SUB_ITEM_STATUS:
      return Object.assign({}, state, {
        selectedMenuHasSubItems: action.payload,
      });

    case MENU_SET_CLASSNAMES:
      return Object.assign({}, state, {
        containerClassnames: action.payload.containerClassnames,
        menuClickCount: action.payload.menuClickCount,
      });

    case MENU_CLICK_MOBILE_MENU:
      return Object.assign({}, state, {
        containerClassnames: action.payload.containerClassnames,
        menuClickCount: action.payload.menuClickCount,
      });

    case MENU_CONTAINER_ADD_CLASSNAME:
      return Object.assign({}, state, {
        containerClassnames: action.payload,
      });

    case MENU_CHANGE_DEFAULT_CLASSES:
      return Object.assign({}, state, {
        containerClassnames: action.payload,
      });

    default:
      return state;
  }
};

export default reducer;
