import { ActionTypes } from "./actionTypes";

export interface LocaleStateType {
  locale: string;
}

export interface ApiVersionStateType {
  [index: string]: string; // Add index signature
  AUTH: string;
  TICKETS: string;
  COLLABORATORS: string;
  USERS: string;
  MARKETING: string;
  INTEGRATIONS: string;
  PROMOTIONS: string;
  REPORT: string;
  LOANS: string;
  SECURITY: string;
}

interface ApiVersionAction {
  type: ActionTypes.CHANGE_API_VERSION;
  payload: ApiVersionStateType;
}

interface ResetAPiVersion {
  type: ActionTypes.RESET_API_VERSION;
}

interface LocaleStateAction {
  type: ActionTypes.CHANGE_LOCALE;
  payload: string;
}

export type InitialStateType = {
  locale: string;
  apiVersions: ApiVersionStateType;
};

export type Action = LocaleStateAction | ApiVersionAction | ResetAPiVersion;

export enum APIS {
  AUTH = "AUTH",
  TICKETS = "TICKETS",
  COLLABORATORS = "COLLABORATORS",
  USERS = "USERS",
  MARKETING = "MARKETING",
  INTEGRATIONS = "INTEGRATIONS",
  PROMOTIONS = "PROMOTIONS",
  REPORT = "REPORT",
  LOANS = "LOANS",
  SECURITY = "SECURITY",
}
