import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../assets/animations/loaders/loader.json";
import { MANGO_MAIN_LOADER_TYPES } from "../../../constants";
import { Options } from "react-lottie";

const { DEFAULT, LIST_VIEW } = MANGO_MAIN_LOADER_TYPES;

const MangoMainLoader = ({ height = 300, width = 300, type = DEFAULT }) => {
  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultStyle: React.CSSProperties = {
    left: `calc(50% - ${width / 2}px)`,
    top: `calc(50% - ${height / 2}px)`,
    zIndex: 1,
    height,
    width,
  };

  let style: React.CSSProperties = { ...defaultStyle };
  switch (type) {
    case LIST_VIEW:
      style = { ...style, position: "absolute" };
      break;
    case DEFAULT:
    default:
      style = {
        ...style,
        position: "fixed",
      };
      break;
  }

  return (
    <div style={style}>
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        isClickToPauseDisabled={true}
      />
    </div>
  );
};

export default MangoMainLoader;
