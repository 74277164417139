import { combineReducers } from "redux";
import currentUser from "./currentUser/reducer";
import menu from "./menu/reducer";
import settings from "./settings/reducer";

const reducers = combineReducers({
  currentUser,
  menu,
  settings,
});

export type IRootState = ReturnType<typeof reducers>;

export default reducers;
