import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/bootstrap.rtl.only.min.css";
import "react-circular-progressbar/dist/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "rc-steps/assets/index.css";
import "rc-slider/assets/index.css";
import "rc-switch/assets/index.css";
import "react-horizontal-scrolling-menu/dist/styles.css";

import {
  isMultiColorActive,
  defaultColor,
  themeColorStorageKey,
  isDarkSwitchActive,
} from "./constants/defaultValues";
const color =
  (isMultiColorActive || isDarkSwitchActive) &&
  localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;

localStorage.setItem(themeColorStorageKey, color || "");

let render = () => {
  /* @ts-ignore */
  import("./assets/css/sass/themes/mango.scss").then((x) => {
    require("./AppRenderer");
  });
  /*import("./assets/css/sass/themes/gogo." + color + ".scss").then((x) => {
    require("./AppRenderer");
  });*/
};
render();
