import initialState from "./initialState";
import { ActionTypes } from "./actionTypes";
import {
  InitialStateType,
  getCurrentUserRoleSuccessAction,
  setCurrentUserInformationAction,
  setCurrentUserAuthStateAction,
  setAuthInformationAction,
  setChangePasswordParamsAction,
  Action,
} from "./types";

const getCurrentUserRoleSuccessHandler = (
  state: InitialStateType,
  action: getCurrentUserRoleSuccessAction
) => {
  const { role } = action.payload;
  return { ...state, role };
};
const setCurrentUserInformationHandler = (
  state: InitialStateType,
  action: setCurrentUserInformationAction
) => {
  const {
    _id,
    email,
    firstName,
    lastName,
    temporalPassword,
    picture,
  } = action.payload;
  return {
    ...state,
    _id,
    email,
    firstName,
    lastName,
    temporalPassword,
    picture,
  };
};

const deleteCurrentUserInformationHandler = () => {
  return initialState;
};

const setCurrentUserAuthStateHandler = (
  state: InitialStateType,
  action: setCurrentUserAuthStateAction
) => {
  const { authState } = action.payload;
  return { ...state, authState };
};

const setAuthInformationHandler = (
  state: InitialStateType,
  action: setAuthInformationAction
) => {
  const { token, refreshToken, expiration, idToken } = action.payload;
  return {
    ...state,
    token,
    refreshToken,
    expiration,
    idToken,
  };
};

const setChangePasswordParamsHandler = (
  state: InitialStateType,
  action: setChangePasswordParamsAction
) => {
  const { id, token } = action.payload;
  return { ...state, urlId: id, urlToken: token };
};

const reducer = (
  state: InitialStateType = initialState,
  action: Action
): InitialStateType => {
  switch (action.type) {
    case ActionTypes.GET_CURRENT_USER_ROLE_SUCESS:
      return getCurrentUserRoleSuccessHandler(state, action);
    case ActionTypes.SET_CURRENT_USER_INFORMATION:
      return setCurrentUserInformationHandler(state, action);
    case ActionTypes.DELETE_CURRENT_USER_INFORMATION:
      return deleteCurrentUserInformationHandler();
    case ActionTypes.SET_AUTH_INFORMATION:
      return setAuthInformationHandler(state, action);
    case ActionTypes.SET_CURRENT_USER_AUTH_STATE:
      return setCurrentUserAuthStateHandler(state, action);
    case ActionTypes.SET_CHANGE_PASSWORD_PARAMS:
      return setChangePasswordParamsHandler(state, action);
    default:
      return state;
  }
};

export default reducer;
