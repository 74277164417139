import { createIntl, createIntlCache } from "react-intl";
import store from "../redux/store";
import en_US from "./locales/en_US";
import es_ES from "./locales/es_ES";

// Se usa para obtener el objeto intl de react-intl fuera de un componente
export const getIntl = () => {
  const locale = store?.getState()?.settings?.locale || "es";
  const messages = locale === "es" ? es_ES : en_US;

  // This is optional but highly recommended
  // since it prevents memory leak
  const cache = createIntlCache();

  const intl = createIntl(
    {
      locale,
      messages,
    },
    cache
  );
  return intl;
};
