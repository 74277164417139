/*
    Table of Contents

    01. User Login, Logout, Register
    02. Sidebar Menu
    03. Sidebar Sub-Menu
    04. Notifications
    05. Buttons
    06. Error 
    07. Marketing 
    08. Development 
*/
// eslint-disable-next-line
export default {
  /* 01. User Login, Logout, Register */
  "user.login-title": "Iniciar sesión",

  /* 02. Sidebar Menu */
  "menu.marketing": "Marketing",
  "menu.development": "Desarrollo",
  "menu.executive": "Gerencia",
  "menu.admin-panel": "Panel de Administración",

  /* 03. Sidebar Sub-Menu */
  "sub-menu.admin-panel.users": "Usuarios",
  "sub-menu.admin-panel.transactions": "Transferencias",
  "sub-menu.admin-panel.channels": "Canales",
  "sub-menu.marketing.events": "Banners",
  "sub-menu.marketing.promos": "Promociones",
  "sub-menu.marketing.push": "Notificaciones Push",
  "sub-menu.marketing.segments": "Segmentos",
  "sub-menu.development.push": "Notificaciones Push",
  "sub-menu.development.push-types": "Tipos de Push",

  /* 04. Notifications */
  "notification.error.fetch": "La lista no ha podido ser actualizada",
  "notification.success.post": "Registro creado exitosamente",
  "notification.error.post": "Hubo un error, el registro no ha sido creado",

  /* 05. Buttons */
  "button.cancel": "Cancelar",
  "button.save": "Guardar Cambios",
  "button.delete": "Eliminar",
  "button.new": "AGREGAR NUEVO",

  /* 06. Error  */
  "error.error-title": "Vaya, parece que ha ocurrido un error!",
  "error.error-code": "Código de error",
  "error.go-back-home": "REGRESAR A INICIO",

  /* 07. Marketing */
  "marketing.events.header": "Eventos",
  "marketing.events.create-modal": "Crear Evento",
  "marketing.events.edit-modal": "Editar Evento",
  "marketing.promos.header": "Promociones",
  "marketing.promos.create-modal": "Crear Promoción",
  "marketing.promos.edit-modal": "Editar Promoción",

  /* 08. Development */
  "development.push-types.header": "Tipos de Push",
  "development.push-types.create-modal": "Crear Tipo de Push",
  "development.push-types.edit-modal": "Editar Tipo de Push",
  "development.push-types.delete-modal-title": "Eliminar Tipo de Push",
  "development.push-types.delete-modal-body":
    "¿Está seguro que desea eliminar el Tipo de Push seleccionado?",

  /*navtabs*/
  "tab.summary": "RESUMEN",
  "tab.transactions": "TRANSFERENCIAS",
  "tab.channels": "CANALES",
  "tab.channel-types": "TIPOS DE CANALES",
  "tab.information": "INFORMACIÓN",

  /* mango administration page */
  "mango-users.edit-user-modal": "Editar Usuario",
  "mango-users.delete-user-modal-title": "Eliminar Usuario",
  "mango-users.delete-user-modal-body":
    "¿Está seguro que desea eliminar el usuario seleccionado?",
  "mango-users.edit-success": "Usuario editado exitosamente",
  "mango-users.get-users-error":
    "No se ha podido actualizar la lista de usuarios",
  "mango-users.delete-success": "Usuario eliminado exitosamente",
  "mango-users.delete-error":
    "No se ha podido eliminar el usuario seleccionado",

  "mango-users.total": "Total",
  "mango-users.active": "Activos",
  "mango-users.inactive": "Inactivos",
  "mango-users.blocked": "Bloqueados",

  /* mango user page */
  "mango-user.mango-data": "Datos de Mango",
  "mango-user.personal-data": "Datos Personales",

  /*channels*/
  "channels.edit-channel-modal": "Editar Canal",
  "channels.delete-channel-modal-title": "Eliminar Canal",
  "channels.delete-channel-modal-body":
    "¿Esta seguro que desea eliminar este canal?",
  "channels.summary-channels-by-type": "Cantidad de canales por tipo",
  "channel-types.edit-channel-type-modal": "Editar Tipo de Canal",

  /*transactions*/
  "transactions.summary.transactions-title": "Transferencias",
  "transactions.summary.transactions-subtitle":
    "Transferencias hechas esta semana",
  "transactions.this-week": "Esta semana",
  "transactions.this-month": "Este mes",
};
