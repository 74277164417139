import { getIntl } from "../lang/utils";

const messages = getIntl().messages as Record<string, string>;

/* authentication states */
export const AUTH_LOADING = "loading";
export const AUTH_SIGN_IN = "signIn"; //not authenticated
export const AUTH_SIGNED_IN = "signedIn"; //authenticated

/*modal names*/
export const DELETE_MODAL = "DELETE_MODAL";

export const BANNER_TYPES = {
  PUSH_MARKETING_EVENT: "PUSH_MARKETING_EVENT", // envia el dashboard
  PUSH_MARKETING_PROMOTION: "PUSH_MARKETING_PROMOTION",
  EVENT: "EVENT",
  PROMOTION: "PROMOTION",
};

export const stateConstants = {
  APROBADA: "APROBADA",
  RECHAZADA: "RECHAZADA",
};

export const patchActions = {
  reject: "reject",
  approve: "approve",
};

export const FILTER_OPERATORS = [
  {
    value: "<",
    label: messages["less-than"].toLowerCase(),
  },
  {
    value: ">",
    label: messages["greater-than"].toLowerCase(),
  },
  {
    value: "=",
    label: messages["equal-to"].toLowerCase(),
  },
  {
    value: "=<",
    label: messages["less-than-or-equal"].toLowerCase(),
  },
  {
    value: "=>",
    label: messages["greater-than-or-equal"].toLowerCase(),
  },
  {
    value: "!=",
    label: messages["not-equal-to"].toLowerCase(),
  },
  {
    value: "exists",
    label: messages["exists"].toLowerCase(),
  },
  {
    value: "not_exists",
    label: messages["not-exists"].toLowerCase(),
  },
];

/* FORMS CONSTANTS */
export const FORM_MODAL_TYPES = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  VIEW: "VIEW",
};

export enum FormModalTypes {
  CREATE = "CREATE",
  EDIT = "EDIT",
  VIEW = "VIEW",
}

export enum PhotoType {
  FC = "FC",
  DC = "DC",
  SELFIE = "SELFIE",
}

export const SMALL_MODAL_TYPES = {
  DELETE: "DELETE",
  REACTIVATE: "REACTIVATE",
  WARNING: "WARNING",
};

export enum SmallModalTypes {
  DELETE = "DELETE",
  REACTIVATE = "REACTIVATE",
  WARNING = "WARNING",
}

/* LOADER CONTANTS */
export const MANGO_MAIN_LOADER_TYPES = {
  DEFAULT: "DEFAULT",
  LIST_VIEW: "LIST_VIEW",
};

export enum MangoMainLoaderTypes {
  DEFAULT = "DEFAULT",
  LIST_VIEW = "LIST_VIEW",
}

/* TICKETS STATES */
export const TICKETS_STATES = [
  { label: messages["label.pending"], value: "pending", color: "primary" },
  {
    label: messages["ticket.status.resolved"],
    value: "resolved",
    color: "success",
  },
  {
    label: messages["ticket.status.expired"],
    value: "expired",
    color: "danger",
  },
  {
    label: messages["ticket.status.no resolution"],
    value: "no resolution",
    color: "warning",
  },
];

export const TICKETS_STATES_CONSTANTS = {
  PENDING: "pending",
  RESOLVED: "resolved",
  EXPIRED: "expired",
  NO_RESOLUTION: "no resolution",
};

/* TRANSACTIONS TYPES*/
export const TRANSACTION_TYPES = [
  {
    label: messages["promotions.service-payment-2"],
    value: "SERVICES_PAYMENT",
  },
  { label: messages["transactions.transfer-order"], value: "TRANSFER_ORDER" },
  {
    label: messages["ticket-details.vaquita-payment"],
    value: "TRANSFER_VAQUITA",
  },
  { label: messages["transactions.money-transfer"], value: "TRANSFER_REQUEST" },
  { label: messages["transactions.recharge"], value: "RECHARGE" },
];

/* ROLES AND PERMISSIONS */
export const PERMISSION_OPERATIONS = {
  ADMIN: "ADMIN",
  READ: "READ",
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const PERMISSION_RESOURCES = {
  INTEGRATION: "Integration",
  REPORT: "Report",
  KYC: "KYC",
  KYC_FAST_VERIFICATION: "KYCFastVerification",
};

export const DEFAULT_FILE_HEADERS = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "X-Custom-User-Agent": `Mango/${process.env.REACT_APP_USER_AGENT_VERSION} BackOffice login`,
  },
  responseType: "arraybuffer" as "arraybuffer",
};
