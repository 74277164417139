import axios from "axios";
import store from "../redux/store";
import {
  setAuthInformation,
  deleteCurrentUserInformation,
  setCurrentUserAuthState,
} from "../redux/currentUser/actions";
import { AUTH_SIGNED_IN, AUTH_LOADING } from "../constants";

// Backoffice
// const BASE_URL = process.env.REACT_APP_API_URL;

const defaultHeaders = {
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  "X-Custom-User-Agent": `Mango/${process.env.REACT_APP_USER_AGENT_VERSION} Backoffice login`,
};

const axiosInstance = axios.create({
  headers: { ...defaultHeaders },
});

// Request interceptor
axiosInstance.interceptors.request.use(function (config) {
  // TODO configurar en el servicio asi se puede decidir cuando un endpoint debe mandar o no token
  const state = store.getState();
  if (
    state &&
    state.currentUser &&
    (state.currentUser.authState === AUTH_SIGNED_IN ||
      state.currentUser.authState === AUTH_LOADING)
  ) {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${state.currentUser.token}`;
      config.headers.refresh = state.currentUser.refreshToken;
    }
  }
  return config;
});

// Response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Si el backend devuelve accessToken o refreshToken lo seteamos globalmente siempre
    //if (response.data['accessToken'] || response.data['refreshToken']) {

    // TODO conectar con el store para guardar el token cuando sea mandado por el back
    // o meterlo en el servicio asi se puede configurar cuando un  mismo endpoint
    // va a guardar el token en auth
    if (response.headers["access-token"] || response.headers["refresh-token"]) {
      const { currentUser } = store.getState();
      const newAuth = {
        token: currentUser.token,
        refreshToken: currentUser.refreshToken,
        idToken: currentUser.idToken,
      };

      if (response.headers["access-token"]) {
        newAuth.token = response.headers["access-token"];
      }
      if (response.headers["refresh-token"]) {
        newAuth.refreshToken = response.headers["refresh-token"];
      }
      if (response.headers["idtoken"]) {
        newAuth.idToken = response.headers["idtoken"];
      }

      const authStore = {
        ...newAuth,
        expiration: response.headers["token-expiration"],
      };
      store.dispatch(setAuthInformation(authStore));
      store.dispatch(setCurrentUserAuthState(AUTH_SIGNED_IN));
      // localStorage.setItem("token", authStore.token);
      // localStorage.setItem("refresh-token", authStore.refreshToken);
    }
    return response;
  },
  function (err) {
    if (err.response) {
      if (err.response.status === 401 || err.response.status === 406) {
        try {
          store.dispatch(deleteCurrentUserInformation());
          // TODO conectar con el store
          //   store.dispatch(deleteCurrentUserInformation());
          // TODO navegar al login
          return Promise.reject(err);
        } catch (error: any) {
          console.error(" ====== ERROR API` ====== ");
          console.error(error, error.response);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default axiosInstance;

// La base de cada servicio
// Ej:
// {
//   APP: '/app',
//   AUTH: '/auth',
// }
export const SERVICE_API_BASES = {
  APP: process.env.REACT_APP_APP_BASE,
  COLLABORATORS: process.env.REACT_APP_API_URL,
  AUTH: process.env.REACT_APP_API_URL_AUTH,
  REPORT: process.env.REACT_APP_API_URL_REPORTS,
  LOANS: process.env.REACT_APP_API_URL_LOANS,
  MARKETING: process.env.REACT_APP_API_URL_MARKETING,
  PROMOTIONS: process.env.REACT_APP_API_URL_PROMOTIONS,
  INTEGRATIONS: process.env.REACT_APP_API_URL_INTEGRATIONS,
  USERS: process.env.REACT_APP_API_URL_USERS,
  TICKETS: process.env.REACT_APP_API_URL_TICKETS,
  SECURITY: process.env.REACT_APP_API_URL_SECURITY,
};
