import { ActionTypes } from "./actionTypes";
import { Role, UserInformation, AuthInformation } from "./types";

export const getCurrentUserRoleSuccess = (role: Role) => {
  return { type: ActionTypes.GET_CURRENT_USER_ROLE_SUCESS, payload: { role } };
};

export const deleteCurrentUserInformation = () => {
  return { type: ActionTypes.DELETE_CURRENT_USER_INFORMATION };
};

export const setChangePasswordParams = (id: string, token: string) => {
  return {
    type: ActionTypes.SET_CHANGE_PASSWORD_PARAMS,
    payload: { id, token },
  };
};

export const setCurrentUserInformation = ({
  _id,
  email,
  firstName,
  lastName,
  temporalPassword,
  picture,
}: UserInformation) => {
  return {
    type: ActionTypes.SET_CURRENT_USER_INFORMATION,
    payload: {
      _id,
      email,
      firstName,
      lastName,
      temporalPassword,
      picture,
    },
  };
};

export const setCurrentUserAuthState = (authState: string) => {
  return {
    type: ActionTypes.SET_CURRENT_USER_AUTH_STATE,
    payload: { authState },
  };
};

export const setAuthInformation = ({
  expiration,
  token,
  refreshToken,
  idToken,
}: AuthInformation) => {
  return {
    type: ActionTypes.SET_AUTH_INFORMATION,
    payload: { expiration, token, refreshToken, idToken },
  };
};
