/* import { addLocaleData } from "react-intl"; */
import enLang from "./entries/en-US";
import esLang from "./entries/es-ES";
import enRtlLang from "./entries/en-US-rtl";

const AppLocale = {
  en: enLang,
  es: esLang,
  enrtl: enRtlLang,
};

export const momentLocaleConfig = {
  es: {
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Setiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    weekdays: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    weekdaysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
    weekdaysShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
  },
  en: {
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    weekdays: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    weekdaysMin: [
      "Ja",
      "Fe",
      "Ma",
      "Ap",
      "Ma",
      "Ju",
      "Ju",
      "Au",
      "Se",
      "Oc",
      "No",
      "De",
    ],
    weekdaysShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
};

export default AppLocale;
