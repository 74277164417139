import { ActionTypes } from "./actionTypes";
import initialState from "./initialState";
import { Action, InitialStateType } from "./types";
const { CHANGE_LOCALE, CHANGE_API_VERSION, RESET_API_VERSION } = ActionTypes;

const reducer = (
  state: InitialStateType = initialState,
  action: Action
): InitialStateType => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };
    case CHANGE_API_VERSION:
      return {
        ...state,
        apiVersions: { ...action.payload },
      };
    case RESET_API_VERSION:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
